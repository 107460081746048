import React from 'react';

import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import ASSETS from '../../assets/images/constants';

import {Wrapper, WrapperHref, Icon} from './styles';

const Footer = () => {
  const {screenType} = useScreenSizeHook();

  return (
    <Wrapper
      className={`font-family-Montserrat ${screenType === SCREEN_TYPES.DESKTOP ? 'text-s' : 'text-l-mob'} font-w-700 white-color`}
      screenType={screenType} url={ASSETS.components.footer.desktop.FooterBackground}
    >
      {screenType === SCREEN_TYPES.DESKTOP && (
        <span className='margin-bottom-s'>
          NOTE: We scan X daily to track engagement and reward you with BF
        </span>
      )}
      <WrapperHref href='https://discord.com/invite/playbossfighters' target='_blank'
        title='Discord' aria-label='Discord'
        className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
      >
                Support
        <Icon src={ASSETS.components.header.desktop.Discord}
          alt='Discord' title='Discord' aria-label='Discord' screenType={screenType}/>
      </WrapperHref>
    </Wrapper>
  );
};

export default Footer;