import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

export const Wrapper = styled.footer<{ url: string, screenType: SCREEN_TYPES }>`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 5 : 8}vw;

    background-image: url(${({url}) => url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    pointer-events: none;
`

export const WrapperHref = styled.a<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    pointer-events: all;

    cursor: pointer;
`

export const Icon = styled.img<{ screenType: SCREEN_TYPES }>`
    width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 8.5 : 27.8}vw;
    height: auto;

    margin: 1% 0 0 5%;
`