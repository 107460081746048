import React, {useState, useEffect} from 'react';

interface ITimer {
    time: number;
}

const Timer = ({time}: ITimer) => {
  const calculateTimeLeft = (): string => {
    const currentTime = new Date().getTime();
    const timeDifference = time - currentTime;

    if (timeDifference <= 0) {
      return '0d 0h 0min';
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}min`;
  };

  const [timeLeft, setTimeLeft] = useState<string>(calculateTimeLeft());

  useEffect(() => {
    const timerInterval = setInterval(() => setTimeLeft(calculateTimeLeft()), 1000);

    return () => clearInterval(timerInterval);
  }, [time]);

  return <div>{timeLeft}</div>;
};

export default Timer;
